import { ReactNode, memo, useReducer } from 'react';
import { USER_PROFILE } from '../constant/storageKey';
import { UserProfileContext, UserProfileDispatchContext } from '../contexts/userContext';
import { userReducer } from '../reducers/userReducer';
import { DiscordInfo, TwitterInfo, UserProfile } from '../types/user';
import { useRequest } from 'ahooks';
import { authTwitterCode, bindDiscord } from '../api/task';

interface IProps {
  children: ReactNode;
}

function createUserProfile(): UserProfile {
  try {
    const userProfile = localStorage.getItem(USER_PROFILE);
    if (userProfile) {
      const _userProfile = JSON.parse(userProfile);
      if (_userProfile?.access_token && _userProfile?.token_valid_until_ms > Date.now()) {
        return _userProfile;
      } else {
        localStorage.removeItem(USER_PROFILE);
      }
    }
  } catch {
    return {};
  }
  return {};
}

const UserProvider = ({ children }: IProps) => {
  const [userProfile, dispatch] = useReducer(userReducer, createUserProfile());

  useRequest<TwitterInfo, void[]>(() => authTwitterCode(), {
    ready: !!userProfile?.access_token,
    onSuccess: (data) => {
      dispatch({ type: 'bindX', payload: { task_x_bind: data } });
    },
  });
  useRequest<DiscordInfo, void[]>(() => bindDiscord(), {
    ready: !!userProfile?.access_token,
    onSuccess: (data) => {
      dispatch({ type: 'updateDiscord', payload: { task_discord_bind: data } });
    },
  });

  return (
    <UserProfileContext.Provider value={userProfile}>
      <UserProfileDispatchContext.Provider value={dispatch}>
        {children}
      </UserProfileDispatchContext.Provider>
    </UserProfileContext.Provider>
  );
};

export default memo(UserProvider);
