import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponseTransformer } from 'axios';
import { stringify } from 'qs';
import { getAccessToken } from '../lib/utils';

let reqNum = 0;

export interface ResponseError {
  message: string | undefined;
  status: number | undefined;
  processed: (() => void) | undefined;
  response: any;
}

class ResponseErrorClass implements ResponseError {
  message: string;
  status: number | undefined;
  processed: (() => void) | undefined;
  response: any;
  apiUrl?: string;
  constructor(msg?: string, status?: number, response?: any, apiUrl?: string) {
    this.message = msg ?? '';
    this.status = status;
    this.response = response;
    this.apiUrl = apiUrl;
  }
}

export const config: AxiosRequestConfig = {
  baseURL: `${import.meta.env.VITE_APP_API_URL}/_api/client/r0`,
  timeout: 20000,
  // withCredentials: true,
  responseType: 'json',
  paramsSerializer: (params: Record<string, any>) => {
    return stringify(params, { arrayFormat: 'comma' });
  },
};

export function setupInterceptors(netApi: AxiosInstance) {
  netApi.interceptors.request.use((request) => {
    const customParams: Record<string, string> = {};
    // add timestamp
    customParams._t = new Date().getTime().toString();

    // add page hash
    const strHash = location.hash;
    if (strHash) {
      // hash中的query内容不进行上报
      const qLocation = strHash.indexOf('?');
      const pageHash = strHash.slice(1, qLocation === -1 ? strHash.length : qLocation);
      customParams._pageHash = pageHash;
    }
    request.params = Object.assign(request.params ?? {}, customParams);

    const token = getAccessToken();
    if (token) {
      request.headers.set('Authorization', `Bearer ${token}`);
    }

    if (reqNum === 0) {
      //   message.loading({
      //     content: '加载中...',
      //     key,
      //     duration: 0,
      //     style: { textAlign: 'center', marginTop: '90px' },
      //   });
    }
    reqNum++;
    return request;
  });

  netApi.interceptors.response.use(
    (response) => {
      if (response.status === 200) {
        return response.data;
      }
      reqNum--;

      return response;
    },
    (error) => {
      reqNum--;
      if (reqNum === 0) {
        // message.destroy();
      }
      const errMsg = error?.message || 'unknow error';
      const { response } = error;
      const reqUrl = `${error?.config?.baseURL}${error?.config?.url}`;
      // 超时处理
      if (error.message.includes('timeout')) {
        return Promise.reject(new ResponseErrorClass(errMsg, response?.status, response, reqUrl));
      }
      if (response) {
        const { status, data } = response;
        // 401 未登录处理
        if (status === 401) {
          setTimeout(() => {
            localStorage.clear();
            location.reload();
          }, 3000);
          return;
        }
        // data.message 可能是无意义的'No message available'
        // 实例化错误
        const responseError = new ResponseErrorClass(data?.message, status, response, reqUrl);

        return Promise.reject(responseError);
      }
      // 没有response 处理
      return Promise.reject(new ResponseErrorClass(errMsg, response?.status, response, reqUrl));
    }
  );
}

export function generateNet(
  config: AxiosRequestConfig,
  setupInterceptors: (netApi: AxiosInstance) => void
) {
  const netApi = axios.create(config);
  setupInterceptors(netApi);
  return netApi;
}

// 生成net对象
export const http = generateNet(config, setupInterceptors);
// 生成返回值带有状态码的codeNet对象
export const codeNet = generateNet(
  {
    transformResponse: [
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
      (data) => {
        // 300200->学情预约页面请求成功的code
        if (data?.code && data.code === 300200) {
          data.code = 0;
        }
        return data;
      },
    ],
    ...config,
  },
  setupInterceptors
);

export function myGet<T>(
  url: string,
  params: Record<string, any> = {},
  config?: Parameters<typeof http.get>[1]
) {
  return http.get<void, T>(`${url}?${stringify(params, { arrayFormat: 'repeat' })}`, config);
}

export const intranetNet = generateNet(config, setupInterceptors);
