import { TaskItemData, TaskVerifyRes } from '../types/task';
import { EmailRequestParams, EmailBindParams, TwitterInfo, DiscordInfo } from '../types/user';
import { http } from './http';

export async function verifyTask() {
  return http.post<void, TaskItemData[]>('/official_activity/verify_task');
}

export async function sendEmail(params: EmailRequestParams) {
  return http.post<void, { sid: string }>('/account/3pid/email/requestToken', {
    send_attempt: 1,
    ...params,
  });
}

export async function bindEmail(params: EmailBindParams) {
  return http.post<void, { sid: string }>('/official_activity/email/add', params);
}

export async function verifyBindEmail() {
  return http.get<void, { task_status: number }>('/official_activity/verify_bind_email');
}

export async function verfiyRepostTweet(tweetId: string) {
  return http.get<void, { task_status: number; completed_wallet?: string }>(
    `/official_activity/twitter/verify_retweeted/${tweetId}`
  );
}

export async function verfiyTwitterFollow(uid: string) {
  return http.get<void, { task_status: number; completed_wallet?: string }>(
    `/official_activity/twitter/verify_follow/${uid}`
  );
}

export async function bindDiscord(params?: { code?: string; overlay_bind?: boolean }) {
  const { code, overlay_bind } = params || {};
  let queryStr = '';
  if (code) {
    queryStr = `?code=${code}`;
  }

  if (overlay_bind !== undefined) {
    queryStr = `?overlay_bind=${overlay_bind}`;
  }

  return http.post<void, DiscordInfo>(`/official_activity/discord/bind${queryStr}`);
}

export async function unbindDiscord() {
  return http.delete(`/official_activity/discord/bind`);
}

export async function verfiyJoinDiscord(squadId: string) {
  return http.get<void, { task_status: number; completed_wallet?: string }>(
    `/official_activity/discord/check_in_squad/${squadId}`
  );
}

export function getTwitterBaseInfo() {
  return http.get<void, any>('/official_activity/twitter/user_base_info');
}

export function authTwitterCode(params?: {
  code?: string;
  overlay_bind?: boolean;
}): Promise<TwitterInfo> {
  const { code, overlay_bind } = params || {};
  if (code) {
    return http.post<void, any>(
      `/official_activity/twitter/oauth2/token?code=${code}&redirect_url=${
        import.meta.env.VITE_APP_REDIRECT_URL
      }`
    );
  } else if (overlay_bind !== undefined) {
    return http.post<void, any>(
      `/official_activity/twitter/oauth2/token?overlay_bind=${overlay_bind}`
    );
  } else {
    return http.post<void, any>(`/official_activity/twitter/oauth2/token`);
  }
}

export function unbindTwitter(): Promise<TwitterInfo> {
  return http.delete<void, any>(`/official_activity/twitter/bind`);
}

export function verfiyAllTask() {
  return http.post<void, TaskVerifyRes>('official_activity/verify_task');
}
