import { RouterProvider } from 'react-router-dom';
import routes from './router';
import './App.css';
import WalletProvider from './providers/WalletProvider';
import UserProvider from './providers/UserProvider';

function App() {
  return (
    <UserProvider>
      <WalletProvider>
        <RouterProvider
          router={routes}
          fallbackElement={''}
          future={{ v7_startTransition: true }}
        />
      </WalletProvider>
    </UserProvider>
  );
}

export default App;
