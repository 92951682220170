import { Dispatch, createContext, useContext } from 'react';
import { UserReducerAction } from '../reducers/userReducer';
import { UserProfile } from '../types/user';

export const UserProfileContext = createContext<UserProfile | null>(null);
export const UserProfileDispatchContext = createContext<Dispatch<UserReducerAction> | null>(null);

export function useUserProfile() {
  return useContext(UserProfileContext);
}

export function useUserProfileDispatch() {
  return useContext(UserProfileDispatchContext);
}
