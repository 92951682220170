import { USER_PROFILE } from '../constant/storageKey';
import { UserProfile } from '../types/user';

export type UserReducerAction = {
  type: string;
  payload: Partial<UserProfile>;
};

export const userReducer = (userProfile: UserProfile, action: UserReducerAction): UserProfile => {
  const _userProfile = { ...userProfile, ...action.payload };
  switch (action.type) {
    case 'initUserProfile':
      localStorage.setItem(USER_PROFILE, JSON.stringify(_userProfile));
      return _userProfile;
    case 'connected':
      localStorage.setItem(USER_PROFILE, JSON.stringify(_userProfile));
      return _userProfile;
    case 'disConnected':
      return _userProfile;
    case 'bindX':
      localStorage.setItem(USER_PROFILE, JSON.stringify(_userProfile));
      return _userProfile;
    case 'updateDiscord':
      localStorage.setItem(USER_PROFILE, JSON.stringify(_userProfile));
      return _userProfile;

    case 'logout':
      return {};
    default:
      return {};
  }
};
